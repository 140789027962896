import React from "react";
import { useWidiaData } from "lang/widia";
import Widia from "sections/Widia/Widia";

const WidiaPage = () => {
  const data = useWidiaData();
  return <Widia data={data} />;
};

export default WidiaPage;
