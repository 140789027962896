import {useWidiaPhotos} from "shared/Hooks/widia/useWidiaPhotos";

export const useWidiaData = () => {
  const photos = useWidiaPhotos();
  console.log(photos);
  return {
    metaInfo: {
      title: "Pełna oferta Widia | WUMAX - dystrybutor narzędzi skrawających",
      ogDescription:
        "Oferujemy innowacyjne narzędzia do obróbki metalu marki Widia, zapewniające precyzyjną i bezpieczną pracę. Zobacz katalogi i zapoznaj się z ofertą.",
      ogImage: photos.logo.childImageSharp.fluid.src,
    },
    hero: {
      title: "Szybka i precyzyjna obróbka metali z narzędziami Widia ",
      subtitle:
        "Widia to marka, która oferuje swoim klientom najlepsze rozwiązania z zakresu obróbki metalu. To producent, który w swojej ofercie posiada narzędzia niezwykle wysokiej jakości - umożliwiające efektywną, bezpieczną i ekonomiczną pracę. Narzędzia Widia to gwarancja Twojego sukcesu.",
      bg: photos.hero,
    },
    mainCatalogue: {
      title: "Katalog główny",
      subtitle:
        "Najnowsza i pełna oferta produktowa marki Widia. Katalog zawiera również szczegółowe informacje oraz dane techniczne umożliwiające zapoznanie się z wytycznymi przydatnymi do korzystania z urządzeń i dokładną analizę prezentowanych rozwiązań.",
      links: [
        {
          text: "Pobierz katalog",
          url: "https://s7d2.scene7.com/s7viewers/html5/eCatalogSearchViewer.html?emailurl=https://s7d2.scene7.com/s7/emailFriend&searchserverurl=http://s7search1.scene7.com/s7search/&serverUrl=https://s7d2.scene7.com/is/image/&config=Scene7SharedAssets/Universal%5FHTML5%5FeCatalog%5FSearch&contenturl=https://s7d2.scene7.com/skins/&config2=companypreset&asset=Kennametal/A%2D15%2D04580%5FMaster17%5FCatalog%5FMetric%5FLR",
        },
      ],
      bigImg: true,
      src: photos.img1,
    },
    toorning: {
      title: "Toczenie",
      subtitle:
        "Urządzenia zapewniające krótszy czas obróbki metalu i wzrost wydajności pracy. Wykorzystane technologie gwarantują wysoką odporność na czynniki termiczne, co wpływa na zachowanie idealnych parametrów obrabianych elementów.",
      links: [
        {
          text: "Pobierz katalog",
          url: "https://s7d2.scene7.com/s7viewers/html5/eCatalogSearchViewer.html?emailurl=https://s7d2.scene7.com/s7/emailFriend&searchserverurl=http://s7search1.scene7.com/s7search/&serverUrl=https://s7d2.scene7.com/is/image/&config=Scene7SharedAssets/Universal%5FHTML5%5FeCatalog%5FSearch&contenturl=https://s7d2.scene7.com/skins/&config2=companypreset&asset=Kennametal/A%2D19%2D06161%5FFinal%5FTurning%5FBrochure%5FSpreads",
        },
      ],
      bigImg: true,
      src: photos.img2,
    },
    innovations: {
      title: "Innowacje Widia",
      subtitle:
        "Katalogi przedstawiające innowacyjne rozwiązania obróbki metalu przy użyciu narzędzi Widia. Urządzenia umożliwiają wydajną pracę oraz pełną precyzję działania przy zachowaniu standardów przyjaznych środowisku.",
      links: [
        {
          text: "Innowacje 2018",
          url: "https://s7d2.scene7.com/s7viewers/html5/eCatalogSearchViewer.html?emailurl=https://s7d2.scene7.com/s7/emailFriend&searchserverurl=http://s7search1.scene7.com/s7search/&serverUrl=https://s7d2.scene7.com/is/image/&config=Scene7SharedAssets/Universal%5FHTML5%5FeCatalog%5FSearch&contenturl=https://s7d2.scene7.com/skins/&config2=companypreset&asset=Kennametal/A%2D17%2D05451%5FWID%5FAdvances18%5FMetric%5FLR",
        },
        {
          text: "Innowacje 2019",
          url: "https://s7d2.scene7.com/s7viewers/html5/eCatalogSearchViewer.html?emailurl=https://s7d2.scene7.com/s7/emailFriend&searchserverurl=http://s7search1.scene7.com/s7search/&serverUrl=https://s7d2.scene7.com/is/image/&config=Scene7SharedAssets/Universal%5FHTML5%5FeCatalog%5FSearch&contenturl=https://s7d2.scene7.com/skins/&config2=companypreset&asset=Kennametal/A%2D18%2D05888%5FWID%5FAdvances19%5FMetric%5FLR%5Fr2",
        },
        {
          text: "Innowacje 2020",
          url: "https://s7d2.scene7.com/s7viewers/html5/eCatalogSearchViewer.html?emailurl=https://s7d2.scene7.com/s7/emailFriend&searchserverurl=http://s7search1.scene7.com/s7search/&serverUrl=https://s7d2.scene7.com/is/image/&config=Scene7SharedAssets/Universal%5FHTML5%5FeCatalog%5FSearch&contenturl=https://s7d2.scene7.com/skins/&config2=companypreset&asset=Kennametal/A%2D19%2D06147%5FWID%5FAdvances20%5FMetric%5FLR%5FiPad",
        },
        {
          text: "Innowacje 2021",
          url: "https://s7d2.scene7.com/s7viewers/html5/eCatalogViewer.html?emailurl=https://s7d2.scene7.com/s7/emailFriend&serverUrl=https://s7d2.scene7.com/is/image/&config=Scene7SharedAssets/Universal%5FHTML5%5FeCatalog&contenturl=https://s7d2.scene7.com/skins/&config2=companypreset&asset=Kennametal/A%2D21%2D06405%5FWIDIA%20Hanita%20Advances%5F2022%5FMetric%5FFINAL%5FLR",
        },
      ],
      bigImg: true,
      src: photos.img3,
    },
    aerospace: {
      title: "Przemysł lotniczy",
      subtitle:
        "Nowoczesne rozwiązania dla przemysłu lotniczego gwarantujące efektywną i precyzyjną pracę. Narzędzia Widia są trwałe i odporne na czynniki zewnętrzne oraz zapewniają zgodność z wszelkimi standardami bezpieczeństwa.",
      links: [
        {
          text: "Pobierz katalog",
          url: "https://s7d2.scene7.com/s7viewers/html5/eCatalogSearchViewer.html?emailurl=https://s7d2.scene7.com/s7/emailFriend&searchserverurl=http://s7search1.scene7.com/s7search/&serverUrl=https://s7d2.scene7.com/is/image/&config=Scene7SharedAssets/Universal%5FHTML5%5FeCatalog%5FSearch&contenturl=https://s7d2.scene7.com/skins/&config2=companypreset&asset=Kennametal/A%2D18%2D05897%5FWID%5FAEROSPACE19%5FCatalog",
        },
      ],
      bigImg: true,
      src: photos.img4,
    },
  };
};
